$primary-color: #3664B1;
$primary-color-hover: #344992;
$primary-color-focus: #304182;

$primary: $primary-color;

$secondary-color: #FF8228;
$secondary-color-hover: #e68626;
$secondary-color-focus: #b15925;

$optimus-gray: #eee;