//Buttons
.primary-btn {
  background-color: $primary-color;
  color: $white;
  border: none;
  transition: background-color .1s ease-in-out;

  &:hover {
    background-color: $primary-color-hover;
    color: $white;
  }

  &:focus, &:active {
    background-color: $primary-color-focus !important;
    color: $white;
  }
}

.secondary-btn {
  background-color: $secondary-color;
  color: $white;
  border: none;
  transition: background-color .1s ease-in-out;

  &:hover {
    background-color: $secondary-color-hover;
    color: $white;
  }

  &:focus, &:active {
    background-color: $secondary-color-focus !important;
    color: $white;
  }
}

// Modal
.optimus-modal {
  top: 50px;
  max-width: 800px;
}

// Utility classes
.outline-none {
  outline: none;
}

// Other

.icon {
  cursor: pointer;
  color: $gray-500;
  transition: color .1s ease-in-out;

  &:hover {
    color: $gray-700;
  }
}

.delete-icon {
  margin-top: 0.2rem
}