.feature-container:not(:first-child) {
  margin: 0.5rem 0;
}

.feature {
  position: relative;
  background-color: $gray-200;
  padding: 0.5rem;
  border: 1px solid $gray-300;
  border-radius: 4px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;
  transition: background-color .1s ease-in-out, border-color .1s ease-in-out;

  &:hover {
    background-color: $gray-300;
    border-color: $primary-color-hover;
  }

  .feature-name {
    font-size: 0.9em;
    font-weight: bold;
  }

  .feature-code {
    min-width: 80px;
    font-size: 0.8em;
    color: $primary-color-hover;
  }
}

.variant {
  border: 1px solid $gray-300;
  margin-left: 0.5rem;
  padding: 0.5rem;
  cursor: pointer;
  background-color: $gray-100;
  border-radius: 4px;

  transition: background-color .1s ease-in-out, border-color, .1s ease-in-out;

  &:hover {
    background-color: $gray-200;
    border-color: $secondary-color-hover;
  }

  .variant-name {
    font-size: 0.9em;
    font-weight: bold;
  }

  .variant-code {
    min-width: 80px;
    font-size: 0.8em;
    color: $secondary-color-hover;
  }
}