.Toastify__toast {
  border-radius: 5px;
  margin-top: 50px;

  font-size: $font-size-sm;
  font-family: $font-family-sans-serif;

  background-color: $primary;

  .toast {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  &.Toastify__toast--success {
    background-color: $success;
  }

  &.Toastify__toast--error {
    background-color: $danger;
  }

  &.Toastify__toast--info {
    background-color: $info;
  }

  &.Toastify__toast--warn {
    background-color: $warning;
  }

  .Toastify__toast-body {
    display: flex;
  }

  .icon {
    display: block;
    height: 100%;
    width: 30px;

    flex-shrink: 0;

    padding: 0 5px;
  }

  .content {
    word-wrap: break-word;
    word-break: break-word;
  }

  .Toastify__progress-bar {
    background-color: rgba(0, 0, 0, 0.3);
  }
}