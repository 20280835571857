.test-title {
  font-size: 1.75rem;
  border: 1px solid $gray-300;
  border-radius: $border-radius;
  outline: none;

  @media screen and (max-width: 980px) {
    font-size: 1.5rem;
  }
}

.card {
  margin-bottom: 10px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.09), 0 1px 1px rgba(0,0,0,0.10);

  .card-header {
    padding: 0.5rem;

    .info-button {
      color: $gray-500;
      transition: color .2s ease-in-out;
      font-size: 1.2rem;

      &:hover {
        color: $gray-600;
      }
    }

    label {
      margin-bottom: 0;
      font-weight: 500;
      font-size: 0.9em;
    }
  }

  .card-body {
    padding: 0.5rem;

    .field-content, p, span {
      font-size: 0.9em;
      outline: none;
    }

    .features-container {
      max-height: 600px;
      overflow: auto;
    }

    .create {
      position: relative;

      input {
        padding-right: 30px;
      }

      .submit {
        position: absolute;
        right: 5px;
        top: 4px;
        color: $text-muted;
        padding: 5px;
        cursor: pointer;
        transition: color .2s ease-in-out;

        &:hover {
          color: $black;
        }
      }
    }

    .search {
      border-bottom: $border-width solid $border-color;
    }

  }

  .country-status-container {
    max-height: 500px;
    overflow: auto;
  }

  .country-status {
    padding: 0.5rem;
    margin: 0.5rem 0;
    border-left: 2px solid $gray-400;
    transition: background-color .1s ease-in-out;

    &:hover {
      background-color: $gray-200;
    }

    .dropdown-menu-icon {
      color: $gray-500;
      transition: color .1s ease-in-out;
      cursor: pointer;

      &:hover {
        color: $gray-700;
      }

      &:active {
        color: $gray-700;

        svg {
          transform: scale(0.9);
        }
      }
    }
  }

  .groups-container {
    max-height: 300px;
    overflow: auto;

    .group {
      padding: 0.5rem;
      margin: 0.5rem 0;
      border-left: 2px solid $gray-400;
      transition: background-color .1s ease-in-out;

      &.dead {
        opacity: 0.5;
      }

      &:not(.dead):hover {
        background-color: $gray-200;
      }
      p, small {
        margin: 0;
      }
      .group-id {
        font-size: 1em;
        font-weight: 500;
      }

      .desc {
        white-space: pre-line;
      }
    }


  }
}

