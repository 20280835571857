.settings-container {
  max-height: 600px;
  overflow: auto;
}

@mixin versioned-setting-color($color) {
  border-left-color: $color;

/*  .heading {
    color: $color;
    border-color: $color;
  }*/

  .type-badge {
    background-color: $color;
  }
}

@mixin versioned-setting($color) {
  > {
    .setting-header {
      @include versioned-setting-color($color);

      &:hover {
        @include versioned-setting-color(scale_color($color, $lightness: -20%));
      }
    }

    .setting-value {
      border-left: $border-width solid rgba($color, $alpha: .3);
    }
  }

}

$versionSettingsType: (
  'string': $green,
  'object': $purple,
  'int': $blue,
  'double': $cyan,
  'array': $orange,
  'boolean': $yellow,
  'null': $pink,
);

$versionSettingsTypeBackground: (
  'object': map_get($versionSettingsType, 'object'),
  'array': map_get($versionSettingsType, 'array'),
);

.setting {
  @each $key, $color in $versionSettingsType {
    &.setting-#{$key} {
      @include versioned-setting($color);
    }
  }

  @each $key, $color in $versionSettingsTypeBackground {
    &.setting-#{$key} > .setting-value {
      background: linear-gradient(90deg, lighten($color, 40%) 0%, lighten($color, 40%) 80%, #fff 100%);
    }
  }

  .setting-header {

    &.grab-hand {
      cursor: grab;
    }

    &:hover {
      background-color: $gray-200;
    }

    display: flex;
    justify-content: space-between;
    background-color: $white;
    border: $border-width solid $gray-300;
    padding: 0.5rem;
    border-left: 2px solid;
    border-radius: $border-radius;
    border-top-left-radius: 0;
    transition: all .1s ease-in-out;
    cursor: pointer;
    box-shadow: 0 0.5px 0 0 #ffffff inset, 0 1px 2px 0 #B3B3B3;

    &.modified {
      background-color: scale-color($info, $lightness: 80%);

      &.hover-from-parent, &:hover {
        background-color: scale-color($info, $lightness: 70%);
      }
    }

    &.deletion {
      background-color: scale-color($danger, $lightness: 80%);

      &.hover-from-parent, &:hover {
        background-color: scale-color($danger, $lightness: 70%);
      }
    }

    &.addition {
      background-color: scale-color($success, $lightness: 80%);

      &.hover-from-parent, &:hover {
        background-color: scale-color($success, $lightness: 70%);
      }
    }

    .caret {
      font-size: 1.4em;
      transition: transform .1s ease-in-out;
      color: $gray-600;
      margin: 0rem 0.5rem 0 0.3rem;

      &.expanded {
        transform: rotate(90deg);
      }
    }

    .inline-edit-button {
      display: none;
      cursor: pointer;
      margin-left: 0.5rem;

      .inline-edit-icon {
        color: $gray-500;
        transition: color .1s ease-in-out;
      }

      &:hover {
        .inline-edit-icon {
          color: $gray-900;
        }
      }
    }

    .heading-container, {
      min-width: 250px;
      min-height: 35px;
      align-items: center;
      display: flex;
      overflow: hidden;

      .marquee {
        transform: translateX(0%);
      }

      &:hover .marquee {
        animation: example1 10s linear infinite;
      }

      &:hover {
        .inline-edit-button {
          display: block;
        }
      }

      .non-editable {
        white-space: nowrap;
      }

      .heading, {
        padding: 0 0 0 0.5rem;
        transition: all .2s ease-in-out;
        background-color: transparent;
        border-color: transparent;

        @keyframes example1 {
          0% {
            transform: translateX(0%);
          }
          50% {
            transform: translateX(-100%);
          }
          50.00000000000001% {
            transform: translateX(100%);
          }
          100% {
            transform: translateX(0%);
          }
        }
      }

      .heading {
        font-size: 0.9em;
        font-weight: 600;

        border: 1px solid lightgray;
        border-radius: 4px;
        background-color: white;

        &.read-only {
          border: none;
          background-color: transparent;
        }
      }
    }

    .right-column {
      display: flex;
      align-items: center;
      padding-left: 0.5rem;

      button {
        &:disabled {
          opacity: 1;
        }
      }

      .type-badge {
        color: $white;
        font-weight: lighter;
        padding: 0.5rem;
        width: 80px;
      }

      .dropdown-menu-icon {
        color: $gray-500;
        transition: color .1s ease-in-out;

        &:hover {
          color: $gray-700;
        }

        &:active {
          color: $gray-700;

          svg {
            transform: scale(0.9);
          }
        }
      }

    }
  }

  .setting-value {
    .collapse > div > div:last-child {
      margin-bottom: 0.5rem;
    }

  }

  .setting-atomic-value {
    width: 100%;
    padding: 0.2rem;
  }
}