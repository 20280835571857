.modal-header {
  display: flex;
  align-items: center;

  .close {
    padding-top: 0.8rem;
  }

  .modal-title {
    width: 100%;

    .edit-feature-header {

      .feature-name-dropdown {
        flex-grow: 1;
        font-size: 0.8em;
        font-weight: 400;
        padding-left: 1rem;
      }
    }
  }
}

.modal-body {
  .tab {
    cursor: pointer;

    &.add-new-variant {
      font-weight: 400;
    }
  }
}

