.page-wrapper {
  display: flex;
  margin-top: 56px;
  height: 100vh;

  .form-wrapper {
    flex: 1;
    max-width: 1200px;
    padding: 2rem 1.5rem 2rem 2rem;
    margin: 0 auto;
  }

  @media screen and (max-width: 980px) {
    margin-top: 80px;

    .page-heading {
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width: map_get($grid-breakpoints, 'md')) {
    margin-top: 27px;

    .form-wrapper {
      padding: 1rem;
    }
  }
}