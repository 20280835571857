$advanced-select-color: $gray-400;
$advanced-select-hover-color: $gray-500;

$advanced-select-option-hover-bg-color: lighten($primary, 35%);

.select-tags {
  display: flex;
  flex-wrap: wrap;

  margin-top: 2px;

  .tag {
    display: flex;

    margin: 2px;

    max-width: 100%;

    background-color: $gray-300;

    border-radius: $border-radius;

    font-size: $small-font-size;
    font-weight: lighter;

    user-select: none;

    .tag-text {
      flex: 1;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      padding: 2px 0 2px 5px;
    }

    .tag-delete {
      padding: 2px 5px;

      cursor: pointer;

      border-radius: $border-radius;

      &:hover {
        background-color: $gray-400;
      }
    }
  }
}

.advanced-select {
  position: relative;

  &.advanced-select-sm {
    .as-field {
      min-height: calc(1.5em + 0.5rem + 2px);

      .as-field-buttons {
        .as-field-button {
          padding: 4px 8px;
        }
      }
    }
  }

  .as-button-flipY {
    transform: scaleY(-1);
  }

  &.disabled {
    .as-field {
      background-color: $input-disabled-bg;
    }
  }

  .as-field {
    background-color: $input-bg;

    border: $input-border-width solid $input-border-color;
    border-radius: $input-border-radius;

    align-items: center;

    color: $input-color;

    cursor: default;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    min-height: $input-height;
    outline: 0 !important;

    position: relative;

    transition: all 100ms;

    &:hover {
      border-color: $advanced-select-hover-color;

      .as-field-buttons {
        color: $advanced-select-hover-color;

        .clear,
        .blacklist {
          color: $advanced-select-color;
        }
      }

      .as-field-buttons-separator {
        background-color: $advanced-select-hover-color;
      }
    }

    &:focus {
      border-color: $input-focus-border-color;

      background-color: $input-focus-bg;
      color: $input-focus-color;

      box-shadow: $input-focus-box-shadow;
    }

    .as-field-select {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      padding: 2px 8px;
      position: relative;
      overflow: hidden;

      .as-field-select-option {
        color: $black;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.singleselect {
        .as-field-select-option {
          margin-left: 2px;
          margin-right: 2px;
          max-width: calc(100% - 8px);
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          white-space: nowrap;
        }
      }

      &.multiselect {
        .as-field-select-option {
          background-color: $gray-300;
          border-radius: 2px;
          display: flex;
          margin: 2px;
          min-width: 0;

          .as-field-select-option-label {
            font-size: 85%;
            padding: 3px 3px 3px 6px;
            white-space: nowrap;
          }

          .as-field-select-option-delete {
            display: flex;
            padding: 0 6px;

            border-radius: 2px;

            &:hover {
              color: $red;
              background-color: lighten($red, 30%);
            }
          }
        }
      }
    }

    .as-field-buttons {
      align-items: center;
      align-self: stretch;
      display: flex;
      flex-shrink: 0;

      color: $advanced-select-color;

      .as-field-button {
        display: flex;
        padding: 8px;

        transition: color 150ms, transform 150ms;

        &.clear:hover,
        &.blacklist:hover {
          color: $advanced-select-hover-color;
        }
      }

      .as-field-buttons-separator {
        align-self: stretch;
        background-color: $advanced-select-color;
        margin: 8px 0;
        width: 1px;

        transition: background-color 150ms;
      }
    }
  }

  .as-dropdown {
    position: absolute;
    top: 100%;
    width: 100%;

    margin: 8px 0;

    z-index: 1;

    background-color: $input-bg;

    border: $input-border-width solid $input-border-color;
    border-radius: $input-border-radius;

    box-shadow: $input-box-shadow;

    .as-dropdown-search {
      input {
        width: 100%;

        border: none;
        border-bottom: $input-border-width solid $input-border-color;

        padding: $input-padding-y $input-padding-x;

        font-family: $input-font-family;
        font-size: $input-font-size;
        font-weight: $input-font-weight;

        outline: none;

        border-top-left-radius: $input-border-radius;
        border-top-right-radius: $input-border-radius;
      }
    }

    .as-dropdown-container {
      position: relative;
      max-height: 300px;

      overflow-y: scroll;

      .as-dropdown-container-option {
        width: 100%;

        background-color: transparent;
        color: inherit;

        font-size: inherit;

        cursor: default;

        user-select: none;

        &.info {
          color: $text-muted;
          text-align: center;

          .as-dropdown-container-option-label {
            cursor: default;
          }
        }

        &:not(.info):hover {
          .as-dropdown-container-option-label {
            background-color: $advanced-select-option-hover-bg-color;
          }
        }

        &.as-dropdown-active {
          .as-dropdown-container-option-label {
            color: $white;
            background-color: $primary;
          }
        }

        .as-dropdown-container-option-label {
          padding: 10px;
          cursor: pointer;
          color: #121212;
          position: sticky;
          top: 0;
        }

        .as-dropdown-expand-to-row {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;

          width: 100%;

          .as-dropdown-column {
            flex: 1;
            text-align: center;
            margin: 5px;
            border: 1px solid $gray-300;
            min-width: 30px;

            cursor: pointer;

            background-color: $white;

            border-radius: $input-border-radius;

            &:hover {
              background-color: $advanced-select-option-hover-bg-color;
            }

            &.as-dropdown-active {
              color: $white;
              background-color: $primary;
            }
          }
        }

        .as-dropdown-category-selector {
          background-color: lighten($primary, 45%);
        }

        .as-dropdown-option-selector {
          flex-wrap: wrap;
        }
      }
    }
  }
}
