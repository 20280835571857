.sidebar {
  $width: 320px;

  min-height: 100%;
  max-height: 100%;
  top: 0;
  right: 0;
  transition: width .5s ease;
  flex: 0 0 auto;
  width: 50px;
  overflow: hidden;
  display: flex;

  &.open {
    width: $width;
  }

  .sidebar-button {
    margin: 10px 10px 10px 0;
    height: 50px;
    width: 50px;
    color: $gray-700;
    font-size: 1.5em;

    &:hover {
      color: $gray-900;
    }
  }

  .sidebar-wrapper {
    width: $width;
    //TODO
    min-width: 260px;
    max-width: $width;
    max-height: 100vh;
    flex: 1;
    overflow: auto;
    border-left: 1px solid $gray-300;
    background-color: $white;
    padding: 1rem 0rem;

    hr {
      margin-bottom: 0.3rem;
    }

    .test-category-title {
      padding: 0.5rem;
      font-weight: 600;
    }

    .test-list {
      list-style: none;
      padding-inline-start: 0;

      .test {
        background-color: $gray-200;
        padding: 0.5rem;
        margin: 0.3rem 0;
        font-size: 0.8em;
        transition: background-color .1s ease-in-out;
        cursor: pointer;

        .test-list-title {
          flex: 1;
        }

        .delete-btn {
          height: 30px;
          margin: 3px;
        }

        &:hover {
          background-color: $gray-300;
        }

        &.selected {
          background-color: $secondary-color;
          color: white;

          &:hover {
            background-color: $secondary-color-hover;
          }
        }
      }
    }
  }
}

@media screen and (max-width: map_get($grid-breakpoints, 'md')) {
  .sidebar {
    position: fixed;

    .sidebar-wrapper {
      padding: 3rem 0.5rem;
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    }

    .sidebar-button {
      background-color: $white;
      border-right: none;
      margin-right: 0;
      margin-top: 30px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    }
  }
}