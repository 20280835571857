// Bootstrap Overrides
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import '~react-toastify/dist/ReactToastify.css';
@import 'toast';

// Page Styling
@import 'global';
@import '../components/components';
@import '../pages/pages';